.content .Help,
.content .InfoAboutUs,
.content .Rules,
.content .PrivacyPolicy {
	padding: 60px 18% 150px 18%;
	background-color: var(--mtc);
}

.content.dimmed {
	background-color: rgba(0, 0, 0, 0.25);
	transition: background-color 0.5s ease;
}

.content {
	transition: background-color 0.5s ease;
}

.Help .MainPart .Heading{
	margin-bottom: 0 !important;
}

/* Добавляем затемняющий слой */
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.25);
	transition: opacity 0.5s ease;
	opacity: 0;
	pointer-events: none;
	z-index: 999;
	/* Выставляем высокий z-index, чтобы он был поверх всего */
}

.overlay.visible {
	opacity: 1;
	pointer-events: all;
}


.MainPart {
	display: flex;
	flex-direction: column;
}

.MainPart span:nth-child(1) {
	text-align: center;
	color: var(--dark-blue);
	font-size: var(--vbfs);
	font-weight: 500;
	margin-top: 0;
}

.MainPart span:nth-child(2) {
	text-align: center;
	padding: 0px 23%;
	margin-bottom: 134px;
	color: var(--light-black);
	font-size: var(--mls);
}

.PrivacyPolicy .MainPart span:nth-child(2) {
	margin-bottom: 0px !important;
	color: var(--light-black);
	font-size: var(--mbfs);
	padding: 0px;
	text-align: left;
}

.MainPart .Text_subheading {
	margin-bottom: 15px;
}

.MainPart .Subheading {
	margin-bottom: 0px !important;
	color: var(--light-black);
	font-size: var(--mbfs);
}

.Subheading {
	margin-bottom: 0px !important;
}

.Heading {
	font-size: var(--mbfs);
	color: var(--light-black);
	margin-bottom: 50px;
}

.PrivacyPolicy .Heading:nth-child(1) {
	font-size: var(--mbfs);
	color: var(--light-black);
	margin-bottom: 50px;
	margin-top: 50px;
}

.PrivacyPolicy .Heading {
	font-size: var(--mbfs);
	color: var(--light-black);
	margin-top: 50px;
}

.Text_subheading {
	color: var(--light-black);
	font-size: var(--mls);
	margin-bottom: 60px;
}

.Text_subheading span {
	padding: 0 !important;
	gap: 0 !important;
	color: var(--light-black) !important;
	font-size: var(--mls) !important;
}

.Text_subheading a,
.Text_subheading i {
	color: var(--dark-blue) !important;
	padding: 0 !important;
	gap: 0 !important;
	color: var(--dark-blue) !important;
	font-size: var(--mls) !important;
}

.InfoAndLinks {
	border-radius: 8px;
	margin-top: 20px;
	background-color: var(--mtc);
}

@media (max-width:1440px) {

	.content .Help,
	.content .InfoAboutUs,
	.content .Rules,
	.content .PrivacyPolicy {
		padding: 60px 6.5% 120px 6.5%;
		background-color: var(--mtc);
	}

	.InfoAndLinks {
		padding: 50px 0px;
	}

	.MainPart span:nth-child(2) {
		margin-bottom: 100px;
	}

	.Text_subheading {
		margin-bottom: 60px;
	}
}


@media (max-width:1024px) {

	.content .Help,
	.content .InfoAboutUs,
	.content .Rules,
	.content .PrivacyPolicy {
		padding: 60px 13.6% 90px 13.6%;
		background-color: var(--mtc);
	}

	.InfoAndLinks {
		padding: 50px 0px;
	}

	.MainPart span:nth-child(2) {
		margin-bottom: 60px;
	}

	.Text_subheading {
		margin-bottom: 50px;
	}
}

@media (max-width:768px) {

	.content .Help,
	.content .InfoAboutUs,
	.content .Rules,
	.content .PrivacyPolicy {
		padding: 50px 14.3% 50px 14.3%;
		background-color: var(--mtc);
	}

	.InfoAndLinks {
		padding: 40px 0px;
	}

	.MainPart span:nth-child(2) {
		margin-bottom: 15px;
	}

	.Text_subheading {
		margin-bottom: 30px;
	}
}

@media (max-width:426px) {

	.content .Help,
	.content .InfoAboutUs,
	.content .Rules,
	.content .PrivacyPolicy {
		padding: 45px 4.2% 45px 4.2%;
		background-color: var(--mtc);
	}

	.InfoAndLinks {
		padding: 25px 0px;
	}

	.MainPart span:nth-child(2) {
		margin-bottom: 45px;
	}

	.Text_subheading {
		margin-bottom: 30px;
	}

	.MainPart span:nth-child(1) {
		text-align: left;
	}

	.MainPart span:nth-child(2) {
		text-align: left;
		padding: 0px 0%;
	}
}

@media (max-width:320px) {

	.content .Help,
	.content .InfoAboutUs,
	.content .Rules,
	.content .PrivacyPolicy {
		padding: 60px 4.6% 60px 4.6%;
		background-color: var(--mtc);
	}
}