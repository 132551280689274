.Footer {
	width: calc(100% - 100px);
	padding: 0px 50px 90px 50px;
	background-color: var(--mtc);
	display: flex;
	flex-direction: column;
	gap: 60px;
	position: relative;
}

.UpFooter {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.UpFooter>div {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.LogoFooter {
	height: 50px;
	object-fit: contain;
}

.LogoFooter a img {
	width: 85px;
	height: 32px;
}

.LinksFooter {
	display: flex;
	flex-direction: column;
	gap: 5px;
	color: var(--light-black) !important;
	font-size: var(--mls) !important;
}


.LinksFooter a {
	color: var(--light-black) !important;
	font-size: var(--mls) !important;
}

.SocialMedia {
	display: flex;
	flex-direction: row;
	gap: 10px;
	font-size: var(--mls);
}

.NameSectionFooter {
	font-size: var(--mls);
	color: var(--dark-blue);
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.NameSectionFooter i {
	margin-left: 10px;
	transition: transform 0.5s;
}

.NameSectionFooter i.open {
	transform: rotate(180deg);
}


.DLeftPartFooter {
	display: flex;
	flex-direction: row;
	gap: 2px;
}

.DLeftPartFooter a>span,
.DLeftPartFooter>span,
.DRightPartFooter>span {
	font-size: var(--mlfs) !important;
	color: var(--light-black) !important;
	gap: 20px;
}

.DownFooter {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: var(--mls);
	color: var(--mtc);
}

.DRightPartFooter .fa-copyright {
	color: var(--light-black) !important; 
	font-size: var(--mls);
	display: flex;
	align-items: center;
}

.DRightPartFooter {
	display: flex;
	flex-direction: row;
	gap: 5px;
}


.NameSectionFooter i {
	display: none;
}

@media (max-width: 1440px) {
	.Footer {
		padding: 0px 50px 50px 50px;
	}

	.LogoFooter a img {
		width: 85px;
		height: 32px;
	}
}

@media (max-width: 1024px) {
	.Footer {
		padding: 0px 50px 40px 50px;
	}
	.LogoFooter a img {
		width: 71px;
		height: 27px;
	}

}

@media (max-width: 769px) {
	.Footer {
		padding: 0px 50px 40px 50px;
	}
	.LogoFooter a img {
		width: 41px;
		height: 16px;
	}

	.DownFooter {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}



	.DLeftPartFooter {
		flex-direction: column;
		gap: 10px;
		align-items: center;
	}

	.DLeftPartFooter>span {
		display: none;
	}

	.UpFooter {
		flex-direction: column;
	}

	.NameSectionFooter {
		padding: 10px 0;
		width: 100%;
	}

	.LinksFooter {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.5s ease;
		width: 100%;
	}


	.LinksFooter.open {
		max-height: 500px;

	}

	.SocialMedia {
		justify-content: center;
		align-items: center;
		margin-top: 20px;
		font-size: var(--vbfs);
		gap: 15px;
	}

	.LogoFooter {
		display: flex;
		justify-content: center;
	}

	.LogoFooter a img {
		width: 65px;
		height: 25px;
	}

	.NameSectionFooter:active,
	.NameSectionFooter:focus {
		background-color: transparent;
	}

	.NameSectionFooter i {
		display: block;
		color: var(--dark-blue) !important;
		font-size: var(--mls) !important;
	}

}

@media (max-width: 426px) {
	.Footer {
		padding: 0px 50px 20px 50px;
	}
	.NameSectionFooter {
		cursor: default;
	}
}

@media (max-width: 320px) {
	.LogoFooter a img {
		width: 64px;
		height: 25px;
	}
}