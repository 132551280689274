.users {
  padding: 20px;
}

.User {
  display: flex;
  align-items: center;
}

.User>a,
.User a>i,
.BurgerButton>i {
  color: var(--light-black) !important;
  font-size: calc(var(--mbfs) + 16px) !important;
}

.search-bar {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.search-bar input,
.search-bar select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  border: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
}

.inactive {
  color: red;
}

@media (max-width:768px) {
  .User>a,
  .User a>i,
  .BurgerButton>i {
    font-size: calc(var(--mbfs) + 8px) !important;
  }

}

@media (max-width:320px) {
  .User>a,
  .User a>i,
  .BurgerButton>i {
    font-size: calc(var(--mbfs) + 4px) !important;
  }

}