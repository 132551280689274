.SearchTickets {
  display: flex;
  flex-direction: column;
  padding: 145px 2.8%;
  border-radius: 8px;
  gap: 40px;
  background-color: var(--mtc);
}

.date-section h2 {
  font-size: var(--mbfs);
  color: var(--dark-blue);
}

@media (max-width:1440px) {
  .SearchTickets {
    padding: 145px 3.3%;
  }
}

@media (max-width:1024px) {
  .SearchTickets {
    padding: 125px 3.4%;
  }
}

@media (max-width:768px) {
  .SearchTickets {
    padding: 60px 3.1%;
  }
}

@media (max-width:425px) {
  .SearchTickets {
    padding: 60px 3.5%;
  }
}