.RoutesPage {
  background-color: var(--mtc);
}

.MainInfoPagesRoutes {
  overflow: hidden;
  justify-content: space-between;
  padding: 140px 16.6%;
  background-color: var(--mbc);
  border-radius: 8px;
}

.InformationRoutesPage {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  padding: 0px 14%;
}

.InformationRoutesPage span {
  font-size: var(--mbfs);
  color: var(--kgiht-black);
}

.NameTitleMainContentRoutesPage {
  font-size: var(--mbfs);
  color: var(--mtcd);
  font-weight: 600;
}

.MainContentRoutesPage {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.AllRoutesList {
  margin: 20px 0px;
  display: grid;
  gap: 20px;
}

.CityItem {
  background-color: var(--dark-blue);
  font-size: var(--mbfs);
  color: var(--mtc);
  padding: 10px 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: calc(0.5 * (1em + 20px));
}

.CityItem::after {
  content: '';
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  line-height: 1;
  font-size: inherit;
}

.CityItem span {
  font-size: var(--mfs);
  color: var(--mtc);
}


.AdditionalInfoRoutesPage {
  display: flex;
  text-align: center;
  font-size: var(--mls);
  color: var(--light-black);
  padding: 0px 14%;
}

/* Responsive styles */
@media (min-width: 1025px) {
  .AllRoutesList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .AllRoutesList {
    grid-template-columns: repeat(3, 1fr);

  }

  .MainInfoPagesRoutes {
    overflow: hidden;
    justify-content: space-between;
    padding: 140px 5%;
    background-color: var(--mbc);
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .AllRoutesList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 420px) {
  .AllRoutesList {
    grid-template-columns: 1fr;
  }

  .MainInfoPagesRoutes {
    overflow: hidden;
    justify-content: space-between;
    padding: 140px 2%;
    background-color: var(--mbc);
    border-radius: 8px;
  }
}