@font-face {
  font-family: 'Benzin';
  src: url('./fonts/Benzin-Regular.woff2') format('woff2'),
    url('./fonts/Benzin-Regular.woff') format('woff'),
    url('./fonts/Benzin-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Benzin';
  src: url('./fonts/Benzin-Medium.woff2') format('woff2'),
    url('./fonts/Benzin-Medium.woff') format('woff'),
    url('./fonts/Benzin-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  font-family: 'Benzin', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

body,
html {
  margin: 0;
  padding: 0;
  padding-inline-start: 0px;
  max-width: 100%;
  overflow-x: hidden;
  width: 100%;
}

a,
i {
  color: var(--light-black);
  font-size: var(--mls);
  text-decoration: none;
}

/* Застосування кольору для всіх дочірніх елементів всередині посилання */
a * {
  text-decoration: none !important;
  color: var(--mtc) !important;
  transition: color 0.5s !important;
}

/* ДОКА ПО ROOT
mtc - main text color - для текста 

mtcd - main text color dark - для текста темный

mls - main little size - размер шрифта для маленького текста

mfs - main font size - размер шрифта для большинства текстов 

mbfs - main big font size - увеличеный размер шрифта для текста

mbc - main background color

*/


:root {
  --mtc: #ffffff;
  --mtcb: #000000;
  --mtcd: #2571ff;
  --mbc: #ffffff;
  --mbcg: #a3a3a3;
  --mbcb: #000000;
  --blue: #1e92ff;
  --dark-blue: #2571ff;
  --light-gray: #949494;
  --gray: #cecece;
  --dark-gray: #a3a3a3;
  --light-black: #494949;
  --dark-gray: #949494;
  --danger-red: #ff3535;
}

@media (max-width:7680px) {
  :root {
    --mls: 28px;
    --mfs: 40px;
    --mbfs: 48px;
    --vbfs: 128px;
  }
}

@media (max-width:5760px) {
  :root {
    --mls: 24px;
    --mfs: 36px;
    --mbfs: 42px;
    --vbfs: 112px;
  }
}

@media (max-width:3840px) {
  :root {
    --mls: 20px;
    --mfs: 28px;
    --mbfs: 32px;
    --vbfs: 96px;
  }
}

@media (max-width:2560px) {
  :root {
    --mls: 16px;
    --mfs: 24px;
    --mbfs: 28px;
    --vbfs: 80px;
  }
}

@media (max-width:1920px) {
  :root {
    --mls: 14px;
    --mfs: 20px;
    --mbfs: 24px;
    --vbfs: 64px;
  }
}

@media (max-width:1440px) {
  :root {
    --mls: 14px;
    --mfs: 18px;
    --mbfs: 24px;
    --vbfs: 64px;
  }
}

@media (max-width:1024px) {
  :root {
    --mls: 10px;
    --mfs: 16px;
    --mbfs: 14px;
    --vbfs: 48px;
  }
}

@media (max-width:768px) {
  :root {
    --mls: 8px;
    --mfs: 14px;
    --mbfs: 14px;
    --vbfs: 36px;
  }
}

@media (max-width:426px) {
  :root {
    --mls: 8px;
    --mfs: 12px;
    --mbfs: 16px;
    --vbfs: 24px;
  }
}

@media (max-width:320px) {
  :root {
    --mls: 8px;
    --mfs: 10px;
    --mbfs: 14px;
    --vbfs: 20px;
  }
}
