.ticket {
  margin: 5px 0;
  margin-top: 20px;
  padding: 40px 75px;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.PassengersTicket,
.DurationTicket{
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.MainInfoTicket {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.FromToTicket {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.FromToTicket div span {
  color: var(--light-black);
  font-size: var(--mbfs);
}

.FromToTicket div span:nth-child(2) {
  color: var(--dark-gray);
  font-size: var(--mls);
}

.TimeTicket div span {
  color: var(--dark-gray);
  font-size: var(--mfs);
}

.TimeTicket {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AddInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AddInfoTicket {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.AddInfoTicket>div {
  display: flex;
  flex-direction: row;
}

.AddInfoTicket div span {
  display: flex;
  flex-direction: row;
  color: var(--dark-gray);
  font-size: var(--mls);
}

.ProgressSymbol {
  position: relative;
  width: calc(100% - 0px);
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.ProgressSymbol .Line {
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: var(--dark-blue);
}

.ProgressSymbol .Circle {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--dark-blue);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProgressSymbol .Circle .InnerCircle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--mtc);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProgressSymbol .SmallCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--dark-blue);
  z-index: 999;
}

.ProgressSymbol .Circle.left {
  left: 0;
}

.ProgressSymbol .Circle.right {
  right: 0;
}

.PriceInfoTicket {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: end;
  height: 100%;
  gap: 10px;
}

.PriceTicket {
  font-size: var(--mbfs);
  display: flex;
  flex-direction: column;
}

.BuyTicket {
  background-color: var(--dark-blue);
  width: calc(100% - 40px);
  height: 100%;
  width: fit-content;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  border-radius: calc(1em + 10px);
}

.BuyTicketText {
  font-size: var(--mls);
  color: var(--mtc);
}

@media (max-width: 1440px) {
  .ticket {
    padding: 40px 60px;
  }
}

@media (max-width: 1024px) {
  .ticket {
    padding: 25px 35px;
  }
}

@media (max-width: 425px) {
  .ticket {
    padding: 25px 25px;
  }

  .PriceInfoTicket {
    flex-direction: column;
    margin-top: 0;
    gap: 0px;
  }

  .AddInfoTicket {
    flex-direction: column;
    margin-top: 0;
    gap: 0px;
  }
}

@media (max-width: 320px) {
  .ticket {
    padding: 20px 20px;
  }
}