.Home {
  color: var(--mtc);
  background-color: #fff;
}

.tawk-message-preview {
  background-color: var(--mtc);
}

.Map .MainContent {
  padding: 50px 0px !important;
  width: 100%;
}

.Map .MainContent .Picking {
  padding: 0px 250px;
  margin-bottom: 50px;
}

.map-container {
  margin-top: 150px !important;
  width: 100%;
}

.Map .MainContent .Picking .TopPicking {
  display: none;
}

.Map .MainContent .Picking .picking-container {
  border-top-left-radius: 40px;
}

.TR {
  background-color: var(--mbcg);
  width: 100%;
  height: 2px;
  margin-top: 30px;
  margin-bottom: 30px;
}


.WelcomminPart {
  background-color: var(--mbc);
  color: var(--blue);
  width: calc(100% - calc(250px*2));
  padding: 0 250px 54px 250px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.TextWelcommingPart {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.MainGreeting {
  font-size: var(--vbfs);
  color: var(--mtcd);
  letter-spacing: -0.01em;
  font-family: 'Benzin' !important;
  font-weight: 500 !important;
  text-align: center;
  letter-spacing: -0.01em;
  color: #2571FF;
}

.SecondGreeting {
  font-size: var(--mls);
  color: var(--dark-gray);
  letter-spacing: -0.01em;
  font-family: 'Benzin' !important;
  font-weight: normal !important;
  font-weight: 400;
  padding: 0px 200px;
}

.PickingPart {
  background-image: url('../background_images/pic-bus-1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
}

.Date span {
  color: var(--mtcb);
  font-size: var(--mfs);
  margin-bottom: 20px;
}

.AdditionalLinks {
  display: flex;
  flex-direction: row;
  gap: 35px;
  justify-content: space-between;
}

.MainContent {
  padding: 220px 15% 150px 15%;
  width: calc(70%);
  background-color: white;
}


.AddLink {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: var(--dark-blue);
  color: var(--mtc) !important;
  transition: 0.5s;
  border-radius: calc(var(--link-height) / 2);
}

.AddLink::after {
  content: '';
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  line-height: 0;
  font-size: 0;
  margin: 0;
  padding: 0;
}

.AddLink {
  --link-height: calc(1em + 80px);
}

.AddLink span {
  margin-left: 25px;
  color: var(--mtc) !important;
  font-size: var(--mbfs);
}

.AddLink i {
  color: var(--mtc) !important;
  font-size: calc(var(--mbfs));
}

.AddLink:hover {
  transition: 0.5s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.AdditionalDescriptions {
  margin: 150px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
  gap: 60px;
}

.AdditionalDescr {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 35px;
  text-align: center;
}

.TextAddLink {
  color: var(--mtcb) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}


.AdditionalDescr i {
  font-size: 40px;
  color: var(--dark-blue) !important;

}

.TextAddLink span:nth-child(1) {
  font-size: var(--mls);
  color: var(--light-black);
}

.TextAddLink span:nth-child(2) {
  font-size: var(--mls);
  color: var(--light-gray);
  font-weight: 400;
}

.MapSegment {
  width: calc(100% + 0px);
  display: flex;
  flex-direction: row;
  gap: 60px;
  background-color: var(--light-gray);
  transition: 0.5s !important;
  background-color: #FFFFFF;
  border-radius: 40px;
  box-shadow: 0px 8px 52.3px rgba(0, 0, 0, 0.19);
}

.ImageMap {
  height: auto;
  object-fit: cover;
  max-width: 45%;
}

.ImageMap img {
  height: 100%;
  width: 100%;
}

.TextMapSeg {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 110px;
  padding: 40px 0px;
  color: var(--mtcb);
}

.TextPartMap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.MainNameMapSeg {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
  font-size: var(--mbfs);
  font-weight: 500 !important;
  color: var(--light-black) !important;
}

.AddNameMapSeg {
  font-size: var(--mls) !important;
  color: var(--mtc) !important;
  padding: 10px 22px;
  background-color: var(--dark-blue);
  border-radius: calc(1em + 20px);
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.AddNameMapSeg i {
  font-size: calc(var(--mls)) !important;
  color: var(--mtc) !important;
}

.SecNameMapSeg {
  font-size: var(--mls);
  color: var(--light-black) !important;
}

.DescriptionOfServices {
  background-color: var(--mbc);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: var(--mtcb);
  margin-top: 100px;
}

.DescriptionOfServices>.TextDescSer:nth-child(1),
.DescriptionOfServices>.TextDescSer:nth-child(3) {
  padding-right: 25%;
}

.DescriptionOfServices>.TextDescSer:nth-child(2) {
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-left: 25%;
}

.TextDescSer {
  margin: 15px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.TextDescSer span:nth-child(1) {
  font-size: var(--mbfs);
  font-weight: 400;
  color: var(--light-black) !important;
}

.TextDescSer span:nth-child(2) {
  font-size: var(--mls);
  color: var(--dark-gray);
  font-weight: 400;
}

.reverse {
  display: flex;
  flex-direction: column-reverse;

}

@media (max-width:1440px) {
  .MapSegment {
    gap: 30px;
  }

  .TextMapSeg {
    padding: 30px 0px;
    gap: 70px;
  }

  

  .Map .MainContent .Picking {
    padding: 0px 50px;
  }

  .AdditionalDescriptions {
    margin: 120px 0px;
    margin-bottom: 120px;
    gap: 30px;
  }

  .MainContent {
    padding: 220px 3% 120px 3%;
    width: 94%;
  }

  .AddLink {
    padding: 17px 0px;
  }

  .WelcomminPart {
    width: calc(100% - calc(100px*2));
    padding: 0 100px 54px 100px;
  }

  .SecondGreeting {
    padding: 0px 50px;
  }

  .AddLink {
    width: 100%;
  }

  .AddLink span {
    font-size: calc(var(--mfs) - 4px);

  }

  .AddLink i {
    font-size: calc(var(--mfs) - 4px);
  }
}

@media (max-width:1024px) {
  .Map .MainContent .Picking {
    padding: 0px 100px;
  }

  .AdditionalDescriptions {
    margin: 120px 0px;
    gap: 35px;
  }

  .AdditionalDescr {
    gap: 20px;
  }

  .WelcomminPart {
    width: calc(100% - calc(60px*2));
    padding: 0 60px 54px 60px;
  }

  .SecondGreeting {
    padding: 0px 50px;
  }

  .AdditionalLinks {
    gap: 40px;
  }

  .MainContent {
    padding: 180px 10.7% 80px 10.7%;
    width: 78.6%;
  }

  .AddLink {
    padding: 11px 0px;
  }

  .AddLink span {
    margin-left: 15px;
  }

  .AddLink {
    width: 100%;
  }

  .MapSegment {
    gap: 25px;
  }

  .TextMapSeg {
    padding: 25px 0px;
    gap: 50px;
  }
}

@media (max-width:768px) {
  .TextAddLink span:nth-child(1){
    font-size: calc(var(--mls) + 4px);
  }
  .Map .MainContent .Picking {
    padding: 0px 75px;
  }

  .AdditionalDescriptions {
    margin: 55px 0px;
  }

  .WelcomminPart {
    width: calc(100% - calc(50px*2));
    padding: 0 50px 54px 50px;
  }

  .SecondGreeting {
    padding: 0px 50px;
  }

  .DescriptionOfServices {
    padding: 20px;
    gap: 0px;
  }

  .AdditionalLinks {
    gap: 30px;
  }

  .MainContent {
    padding: 160px 7.5% 30px 7.5%;
    width: 85%;
  }

  .AddLink span {
    margin-left: 10px;
  }

  .AddLink {
    padding: 8px 0px;
    width: 100%;
  }

  .AddLink i,
  .AddLink span {
    font-size: var(--mbfs);
  }

  .AddLink i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
  }

  .MapSegment {
    gap: 25px;
  }

  .TextMapSeg {
    padding: 20px 0px;
    gap: 50px;
  }

  
}

@media (max-width:550px) {
  .ImageMap {
    height: auto;
    object-fit: cover;
    max-width: 100%;
  }

  .Map .MainContent {
    padding-top: 0px;
  }

  .Map .MainContent .Picking {
    padding: 0px 45px;
  }

  .MapSegment {
    flex-direction: column;
    width: 100%;
    padding: 0 0px 20px 0px;
    margin: 30px 0px;
  }

  .DescriptionOfServices {
    display: none;
  }

  .MapSegment>div {
    width: 100%;
  }

  .TextPartMap,
  .AddNameMapSeg {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .AdditionalLinks {
    gap: 15px;
  }

  .MainContent {
    padding: 160px 2.5% 30px 2.5%;
    width: 95%;
  }

  .AdditionalDescriptions {
    margin: 80px 0px;
    gap: 55px;
    flex-direction: column;
  }

  .AdditionalDescr {
    gap: 35px;
  }

  .reverse {
    flex-direction: column;
  }
}

@media (max-width:426px) {
  .Map .MainContent .Picking {
    padding: 0px 10px;
  }

  .AdditionalLinks {
    flex-direction: column;
  }

  .MainContent {
    padding: 120px 60px 20px 60px;
    width: calc(100% - 120px);
  }

  .AddLink span {
    margin-left: 10px;
  }

  .WelcomminPart {
    text-align: left !important;
    width: calc(100% - calc(30px*2));
    margin-left: 20px;
    padding: 0 0px 26px 0px;
  }

  .MainGreeting {
    text-align: left !important;
  }

  .SecondGreeting {
    text-align: left !important;
    padding: 0px 0px;
  }

  .TextMapSeg {
    gap: 60px;
  }

  .MainNameMapSeg {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: flex-end;
  }


  .AdditionalDescriptions {
    margin: 50px 0px;
    flex-direction: column;
    gap: 30px;
  }

  .AdditionalDescr span {
    width: 100%;
  }

  .AddLink span {
    margin-left: 3px;
  }


  .TextMapSeg {
    gap: 20px;
  }

  .SecondGreeting {
    text-align: left !important;
    padding: 0px 0px;
    font-size: 6px;
    padding-right: 50px;
    font-family: 'Benzin-Regular';
    font-style: normal;
    font-weight: 400;
  }
}

@media (max-width:320px) {
  .AdditionalDescriptions {
    margin: 55px 0px;
    gap: 40px;
  }

  .AdditionalLinks {
    flex-direction: column;
  }

  .MainContent {
    padding: 100px 75px 20px 35px;
    width: calc(100% - 60px);
  }

  .AdditionalDescr {
    gap: 20px;
  }
}