.Account {
	display: flex;
	flex-direction: column;
	padding: 100px 3.5%;
	background-color: var(--mtc);
}

.MainContentAccount {
	overflow: hidden;
	justify-content: space-between;
}

.WelcomePartAccount {
	display: flex;
	flex-direction: row;
	gap: 5px;
}

.UserWelcome {
	background-color: var(--mtc);
	border-radius: 8px;
	width: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 20px;
}

.UserWelcome h1 {
	font-size: var(--vbfs);
	color: var(--dark-blue);
	font-weight: 400;
}

.UserWelcome h2 {
	font-size: var(--mls);
	color: var(--light-black);
	font-weight: 400;
}

.InfoTravelsUser {
	font-size: var(--mfs);
	color: var(--mtcb);
}

.FutureTravels {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	margin-top: 40px;
}

.FutureTravels>div {
	display: flex;
	flex-direction: row;
	width: 100% !important;
	align-items: center;
	justify-content: center;
}

.TravelsInfo {
	margin-top: 0px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	text-align: center;
}

.TravelsInfo>span {
	color: var(--light-black);
	font-size: var(--mbfs);
}

.TravelsAcc,
.PastTravelsAcc {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: calc(100% - 80px);
	align-self: center;
	margin: 0px !important;
	padding-bottom: 20px;
	overflow: visible;
}

.Trip {
	border-radius: 8px;
	padding: 20px;
	gap: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.DownpartACC{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 100%;
}

.MainInfo{
	display: flex;
	flex-direction: row;
	align-items: stretch;
	height: 100%;
}

.Time{
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	margin-right: 20px;
	font-size: var(--mfs);
}

.Time div {
	display: flex;
	flex-direction: column;
}

.Route {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: start;
	gap: 40px;
	font-size: var(--mbfs) !important;
}

.Place {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.Place span:nth-child(1) {
	font-size: var(--mbfs);
	color: var(--mtcb);
}

.Place span:nth-child(2) {
	font-size: var(--mfs);
	color: var(--mtcb);
}

.RouteSymbol {
	position: relative;
	width: fit-content;
	margin: 0 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.RouteSymbol .Line {
	width: 3px;
	flex-grow: 1;
	background-color: var(--dark-blue);
}

.RouteSymbol .Circle {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: var(--dark-blue);
	display: flex;
	align-items: center;
	justify-content: center;
}

.SmallCircle {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: var (--dark-blue);
	z-index: 999;
}

.RouteSymbol, .Route {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TimeArr{
	display: flex;
	align-items: flex-end;
	align-self: flex-end;
}

.Route {
  flex-grow: 1; 
}

.AdditionalInfo {
	display: flex;
	flex-direction: column;
	gap: 20px;
	font-size: var(--mfs);
}

.DayTrips {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.ShowAllButton {
	width: fit-content;
	padding: 12px 34px;
	background-color: var(--dark-blue);
	border: none;
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	transition: 0.5s ease !important;
	cursor: pointer;
	border-radius: calc(1em + 24px);
	align-self: center;
}

.ShowAllButton span {
	font-size: var(--mls) !important;
	color: var(--mtc) !important;
	cursor: pointer;
}

.ProposeBuyTicketAcc {
	margin-top: 0 !important;
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: flex-start !important;
	align-items: center;
	text-align: center;
}

.ProposeBuyTicketAcc>span {
	text-align: center;
	color: var(--light-black);
	font-size: var(--mls);
}

.ButtonBuyTicketsAcc {
	width: fit-content;
	padding: 12px 60px;
}

.Logout {
	margin-top: 40px;
	height: fit-content;
	border-radius: 8px;
	background-color: var(--mtc);
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 20px;
}

.NameLogout{
	display: flex;
	flex-direction: column;
	gap: 5px;
	text-align: center;
}

.NameLogout span:nth-child(1)  {
	font-size: var(--mbfs);
	color: var(--light-black);
}

.NameLogout span:nth-child(2) {
	font-size: var(--mls);
	color: var(--dark-gray);
}

.ButtonLogout button {
	height: 40px;
	width: fit-content;
	border: none;
	background-color: var(--danger-red);
	font-size: var(--mfs);
	color: var(--mtc);
	padding: 10px 65px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	border-radius: calc(1em + 20px);
}

@media (max-width: 1440px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
	.RouteSymbol .Line {
		width: 2px;
	}

	.RouteSymbol .Circle {
		width: 14px;
		height: 14px;
	}

	.Trip {
		padding: 10px;
		gap: 25px;
		flex-direction: column;
	}

	.DownpartACC{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 25px;
	}

	.AdditionalInfo {
		flex-direction: row;
		gap: 50px;
	}

}

@media (max-width: 425px) {
}

@media (max-width: 320px) {

	.RouteSymbol .Circle {
		width: 10px;
		height: 10px;
	}
}