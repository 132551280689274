.BuyTicketUser {
  padding: 20px 5% 60px 5%;
  gap: 20px;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.MainInfoBuyTicketUser {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
}

.InfoBuyTicketUser h1 {
  font-size: var(--mbfs);
  color: var(--dark-blue);
  font-weight: 500;
}

.TicketInfoBuy {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.Stage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  width: 35px;
  height: 35px;
  background-color: var(--dark-blue);
  color: var(--mtc);
  border-radius: 50%;
}

.NameTitle {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.Stage {
  font-size: var(--mls) !important;
  color: var(--mtc);
}

.NameTitle span:nth-child(2) {
  font-size: var(--mls) !important;
  color: var(--light-black);
  font-weight: 500;
}

.MainInputsNameUser {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.MainInputsNameUser input {
  width: 50%;
  height: 40px;
  border-radius: 20px;
  border: 1px solid var(--light-gray);
  padding: 0px 0px 0px 10px;
  transition: border-color 0.5s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.MainInputsNameUser input:focus-visible {
  outline: 2px solid var(--gray);
}

.MainInputsNameUser input::placeholder {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.StageDiv {
  display: flex;
  flex-direction: column;
  background-color: var(--mtc);
  border-radius: 40px;
  padding: 30px;
  gap: 14px;
  box-shadow: 0px 8px 52.4px rgba(0, 0, 0, 0.19);
}

.AddInfoTicketBuy {
  width: 30%;
  background-color: var(--mtc);
  padding: 20px;
  border-radius: 8px;
  height: fit-content;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.BaggageDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.BaggageDiv span {
  font-size: var(--mls);
  color: var(--light-black);
}

.AddInfoBaggage {
  font-size: var(--mls) !important;
  color: var(--dark-gray);
}

.InfoBaggageType {
  display: flex;
  flex-direction: column;
}

.picking-baggage,
.picking-NumberPassengers {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.picking-baggage input,
.picking-NumberPassengers input{
  width: 60px;
  height: 36px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: var(--mfs);
  background-color: var(--mtc);
}

.picking-baggage input:focus-visible,
.picking-NumberPassengers input:focus-visible{
  outline: none;
}

.GorisontalLine {
  height: 1px;
  width: 100%;
  margin: 20px 0px;
  background-color: black;
}

.LargeBaggage {
  margin-top: 20px;
}

.NPassDivInfo{
  display: flex;
  flex-direction: row;
  gap: 3px;
  font-size: var(--mls);
  color: var(--dark-gray);
}

.picking-baggage button,
.picking-NumberPassengers button {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--blue);
  border: none;
  color: var(--mtc);
  font-size: var(--mfs);
  font-size: 7px !important;
}

.DataContactUserMain {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.DataContactUserMain>div {
  width: 50%;
  height: 40px;
}

.DataContactUserMain div input {
  width: 100%;
  height: 100%;
  font-size: var(--mfs);
  border: 1px solid var(--gray);
  border-radius: 20px;
  padding: 0px 0px 0px 10px;
  transition: border-color 0.5s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.DataContactUserMain div input:focus-visible {
  border: 1px solid var(--gray);
  border-radius: 4px;
}

.phone-input {
  height: 40px;
}

.phone-input>div {
  width: 100%;
  height: 40px;
  border-radius: 20px;
}

.form-control {
  height: 40px !important;
  width: 100% !important;
  transition: border-color 0.5s;
}

.phone-input input {
  width: 100%;
  height: 40px;
  font-size: var(--mfs);
  border: 1px solid var(--gray);
  border-radius: 4px;
  padding: 0px 0px 0px 10px;
}

.ButtonConfirmBuyTicket button {
  margin-top: 20px;
  width: 100%;
  padding: 10px 0px;
  font-size: var(--mls);
  color: var(--mtc);
  border: none;
  background-color: var(--blue);
  cursor: pointer;
  transition: background-color 0.5s;
  border-radius: calc(1em + 20px);
}

.ButtonConfirmBuyTicket button:disabled {
  background-color: var(--danger-red);
}

.ButtonConfirmBuyTicket button:hover {
  transition: 0.5s;
  background-color: var(--dark-blue);
}

.ProgressTicketBuy {
  display: flex;
  flex-direction: row;
}

.ProgressSymbolVertical {
  height: 60px;
}

.ProgressSymbolVertical .LineTB {
  background-color: var(--dark-blue);
  width: 3px;
  height: 100%;
}

.ProgressTicketBuyVertical {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  /* Додаємо вирівнювання по висоті */
}

.FromToTicketTicketBuyVertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: var(--mfs);
  flex-grow: 1;
  /* Додаємо для адаптивної висоти */
}

.FromTicket,
.ToTicket {
  display: flex;
  flex-direction: column;
}

.ToTicket {
  text-align: end;
}

.RightPartBaggage {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.RightPartBaggage>div:nth-child(2)>span {
  color: var(--dark-gray);
}

.AddInfoTicketBT,
.PriceBuyTicket {
  margin-top: 20px;
  margin-left: 10px;
  font-size: var(--mfs);
}

.FromToTicketTicketBuyVertical {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.FromToTicketTicketBuyVertical>div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.CityInfoTicketBuy {
  font-size: var(--mls);
  color: var(--light-black);
}

.StreetInfoTicketBuy {
  font-size: var(--mls);
  color: var(--mtcb);
}

.DepartureInfoTicketBuy {
  font-size: var(--mls);
  color: var(--dark-gray);
}

.DurationTicket {
  font-size: var(--mls);
  color: var(--dark-gray);
}

.TicketPrice span {
  font-size: var(--mls);
  color: var(--dark-gray);
}

.TicketPrice span:nth-child(2) {
  color: var(--dark-blue);
}

.RouteSymbolBuyTicket {
  position: relative;
  width: 20px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.RouteSymbolBuyTicket .Line {
  position: absolute;
  width: 3px;
  height: 100%;
  background-color: var(--dark-blue);
}

.RouteSymbolBuyTicket .Circle {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--dark-blue);
  display: flex;
  align-items: center;
  justify-content: center;
}

.RouteSymbolBuyTicket .Circle .InnerCircle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--mtc);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SmallCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var (--dark-blue);
  z-index: 999;
}

.RouteSymbolBuyTicket .Circle.top {
  top: 0;
}

.RouteSymbolBuyTicket .Circle.bottom {
  bottom: 0;
}

.react-tel-input .country-list {
  z-index: 10001;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 80vw;
  height: 50vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 25vh 10vw;
}

.modal-content {
  width: 65.3vw;
  height: fit-content;
  padding: 80px 13.9% ;
  background-color: var(--mbc);
  border-radius: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28vh;
}

.modal-content>span {
  font-style: var(--mfs);
  color: var(--dark-blue);
  text-align: center;
}

.modal-content>button {
  display: flex;
  padding: 10px 40px;
  background-color: var(--light-black);
  font-size: var(--mfs);
  color: var(--mtc);
  border-radius: calc(1em + 20px);
  transition: 0.5s;
  justify-content: center;
  align-items: center;
  border: none;
}

.modal-content>button:hover {
  background-color: var(--dark-blue);
  transition: 0.5s;
  cursor: pointer;
}

body.modal-open {
  overflow: hidden;
}


@media (max-width:768px) {
  .RouteSymbolBuyTicket {
    height: fit-content;
  }

  .NameTitle {
    gap: 10px;
  }

  .Stage {
    width: 30px;
    height: 30px;
  }

  .MainInfoBuyTicketUser {
    flex-direction: column;
  }

  .MainInfoBuyTicketUser>div {
    width: 100%;
  }

  .AddInfoTicketBuy {
    width: calc(100% - 40px) !important;
  }

  .FromToTicketTicketBuyVertical {
    font-size: var(--mbfs);
  }

  .AddInfoTicketBT,
  .PriceBuyTicket {
    font-size: var(--mbfs);
  }

  .RouteSymbolBuyTicket .Circle {
    width: 14px;
    height: 14px;
  }
}

@media (max-width:426px) {
  .DataContactUserMain {
    flex-direction: column;
  }

  .DataContactUserMain>div {
    width: 100%;
  }

  .EnterEmailBT input {
    width: calc(100% - 10px) !important;
  }

  .picking-baggage input,
  .picking-NumberPassengers input {
    width: 40px;
    height: 26px;
  }

  .MainInputsNameUser {
    flex-direction: column;
  }

  .MainInputsNameUser input {
    width: calc(100% - 10px);
  }
}