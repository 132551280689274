.PickingPart {
  padding: 120px 15% 0 15%;
  padding-top: 500px;
}

.TopPicking {
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(1.9px);
  border-radius: 40px 40px 0px 0px;
  padding: 10px 45px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: fit-content;
  align-items: center;
}

.TopPicking i {
  color: var(--dark-blue) !important;
  font-size: var(--mfs) !important;
}

.TopPicking span {
  color: var(--mtcb) !important;
  font-size: var(--mfs) !important;
}

.css-hlgwow {
  color: var(--light-black);
  font-size: var(--mls);
}

.picking-container {
  margin-bottom: -100px;
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 40px 45px;
  color: var(--dark-blue);
  border-radius: 8px;
  box-shadow: 0px 8px 52.3px rgba(0, 0, 0, 0.19);
  font-size: var(--mfs);
  background-color: #fff;
  border-radius: 0px 40px 40px 40px;
  z-index: 999;
  gap: 3%;
}

.picking-passengers input[type=number]::-webkit-outer-spin-button,
.picking-passengers input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.picking-passengers input:focus-visible {
  outline: none;
}

.from-to-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  width: 100%;
  position: relative;
}

.from-to-container .picking-field,
.from-to-container .from-field {
  width: 50% !important;
}

.picking-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.from-field,
.to-field {
  width: 48%;
}

.css-522vqn-control .css-hlgwow .css-13xzpp8-singleValue{
  display: none;
}

.picking-select {
  width: 100%;
}

.swap-button {
  height: 40px;
  width: 40px;
  border: none;
  background-color: var(--blue);
  color: var(--mtc);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s;
  z-index: 999;
}

.swap-button:hover {
  background-color: var(--dark-blue);
}

.from-field label {
  margin-left: 8px !important;
}

.to-field label {
  margin-left: 30px !important;
}

.css-qbdosj-Input,
.css-3m5cbd-Input {
  padding-left: 20px !important;
}

.css-166bipr-Input,
.css-1ve7j4l-Input {
  padding-left: 20px !important;
}

.css-166bipr-Input:after {
  margin: 0px 0px 0px 20px !important;
}

.picking-datepicker {
  height: 22px;
}

.picking-datepicker {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--gray);
  color: var(--light-black);
  font-size: var(--mls);
}

.picking-passengers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 130px;
}

.picking-passengers button {
  height: 30px;
  width: 30px;
  border: none;
  background-color: var(--dark-blue);
  color: var(--mtc);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
}


.picking-passengers input {
  width: 40%;
  height: 40px;
  text-align: center;
  border: 1px solid var(--gray);
  border-radius: 4px;
  border: none;
}


.picking-search {
  background-color: var(--light-black);
  color: var(--mtc);
  font-size: var(--mls);
  height: 40px;
  width: 170px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
}

.picking-search i {
  color: var(--mtc) !important;
  font-size: var(--mls) !important;
}

.picking-search:hover {
  background-color: var(--dark-blue);
}

.additional-fields {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.from-field .css-13xzpp8-singleValue {
  margin-left: 0px !important;
  margin-right: 0 !important;
}

.to-field .css-1jqq78o-placeholder {
  margin-left: 20px !important;
  margin-right: 0 !important;
}

#react-select-3-input {
  margin-left: 20px !important;
}

/* Responsive styles */
@media (max-width: 1920px) {
  .additional-fields {
    flex-direction: row;
  }

  .from-to-container {
    flex-direction: row;
  }

  .picking-field {
    width: 30%;
  }

  .picking-field:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1680px) {
  .picking-container {
    flex-direction: column;
    gap: 15px;
  }
}

@media (max-width: 1440px) {
  .PickingPart {
    padding: 120px 3% 0px 3%;
    width: 94%;
  }

  .from-to-container {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }

  .additional-fields {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .swap-button {
    margin-top: 26px;
    height: 37px;
    width: 37px;
  }

  .picking-container {
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .picking-container {
    align-items: flex-start;
    position: relative;
  }

  .additional-fields .picking-field:nth-child(1) {
    width: calc(62.5% - 20px);
  }

  .additional-fields .picking-field:nth-child(2) {
    width: calc(37.5% - 19px);
  }

  .from-field,
  .to-field {
    width: 100%;
  }

  .picking-select {
    width: 100%;
  }

  .picking-datepicker {
    width: calc(100% - 16px);
  }

  .picking-search {
    width: 30%;
    right: 20px;
  }
}

@media (max-width: 1024px) {


  .PickingPart {
    padding: 60px 10.7% 0px 10.7%;
    width: 78%;
    padding-top: 400px;
  }

  .swap-button {
    margin-top: 20px;
  }

  .picking-passengers button {
    width: 25px;
    height: 25px;
  }

  .picking-passengers input {
    height: 40px;
  }

  .picking-passengers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 104px;
  }

  .TopPicking span{
    font-size: calc(var(--mfs) - 2px);
  }
}


@media (max-width: 768px) {
  .PickingPart {
    padding: 60px 7.5% 0px 7.5%;
    width: 85%;
    padding-top: 300px;
  }

  .picking-container {
    padding-bottom: 74px;
    gap: 0;

  }

  .picking-field {
    width: 100%;
    margin-bottom: 10px;
  }


  .picking-search {
    display: flex;
    align-self: center;
    text-align: center;
    width: 100%;
  }

  .swap-button {
    width: 36px;
    height: 36px;
  }

  .additional-fields {
    width: 100%;
    flex-direction: column;
  }

  .additional-fields .picking-field:nth-child(1) {
    width: calc(100% - 0px);
  }

  .additional-fields .picking-field {
    gap: 5px !important;
  }

  .picking-field label {
    margin-left: 15px !important;
  }

  .additional-fields .picking-field:nth-child(2) {
    width: 90px;
  }

  .picking-passengers {
    margin-left: 15px;
    gap: 0;
    width: 90px;
  }

  .from-field label {
    margin-left: 8px !important;
  }


  #react-select-3-input {
    margin-left: 4px !important;
  }

  .from-field .css-13xzpp8-singleValue,
  .to-field .css-13xzpp8-singleValue {
    margin-left: 4px !important;
  }

  .from-to-container {
    flex-direction: column;
  }

  .from-to-container .picking-field,
  .from-to-container .from-field .css-b62m3t-container {
    width: 100% !important;
    gap: 5px;
  }

  .swap-button {
    width: 38px;
    height: 38px;
    margin-top: 12px;
    margin-left: 50%;
    transform: translateX(-100%);
  }

  .css-13xzpp8-singleValue {
    margin-right: 0 !important;
  }

  .css-1j28y3l-control {
    padding-right: 5px !important;
  }

  .PickingPart {
    padding: 60px 60px 0px 60px;
    width: calc(100% - 120px);
    padding-top: 50px;
  }

  .picking-container {
    padding-bottom: 84px;
    padding: 20px 15px 20px 15px;
  }

  .css-qbdosj-Input,
  .css-3m5cbd-Input {
    padding-left: 5px !important;
  }

  .css-166bipr-Input,
  .css-1ve7j4l-Input {
    padding-left: 5px !important;
  }

  .css-166bipr-Input:after {
    margin: 0px 0px 0px 5px !important;
  }

  .css-13xzpp8-singleValue {
    margin-left: 5px !important;
  }

  .to-field label,
  .from-field label {
    margin-left: 15px !important;
  }

  .picking-passengers button {
    width: 18px;
    height: 18px;
  }

  .TopPicking {
    padding: 10px 15px;
  }

  .picking-passengers input {
    height: 28px;
  }

  .picking-datepicke {
    height: 14px;
  }

  .additional-fields {
    align-items: flex-start;
  }

  .additional-fields .picking-field {
    gap: 5px;
  }

  .from-field label,
  .to-field label {
    margin-left: 15px !important;
  }

  .picking-datepicker {
    height: 22px !important;
    padding-left: 13px;
  }

  .to-field .css-1jqq78o-placeholder {
    margin-left: 00px !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 550px) {
  .PickingPart {
    padding: 60px 2.5% 0px 2.5%;
    width: 95%;
  }
}

@media (max-width: 426px) {
  .picking-search {
    height: 30px;
    border-radius: 15px;
  }
}

@media (max-width: 320px) {
  .PickingPart {
    padding: 60px 75px 0px 35px;
    width: calc(100% - 60px);
  }

  .picking-container{
    padding: 20px 15px 20px 15px;
  }

  .swap-button {
    width: 38px;
    height: 38px;
    margin-top: 5px;
  }
}