.Header {
	display: flex;
	align-items: center;
	padding: 42px 53px;
	background-color: var(--mtc);
	color: white;
	justify-content: space-between;
	font-size: var(--mls)
}

.links a {
	text-decoration: none;
	/* Убираем стандартное подчёркивание у всех ссылок */
}

.links a.active {
	text-decoration: underline !important;
	text-decoration-color: var(--dark-blue) !important;
	text-decoration-thickness: 2px !important;
	text-underline-offset: 4px !important;
}

.Navigation {
	background-color: var(--blue);
	color: var(--mtc);
	font-size: var(--mfs);
	display: flex;
	flex-direction: row;
	width: 90%;
	height: 50px;
	position: fixed;
	padding: 10px 5%;
	position: relative;
}

.Logo {
	object-fit: contain;
}

.Logo a {
	display: block;
}

.Logo img {
	width: 85px;
	height: 32px;
}

.links {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	align-self: center;
	gap: 60px;
}

.links a {
	text-decoration: none;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	width: calc(100% - 40px);
	padding: 0px 0px;
}

.links a span,
.links a i {
	color: var(--dark-blue) !important;
	font-size: var(--mfs);
}

.links a i {
	text-decoration: none !important;
}

.links-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	align-items: center;
	margin-left: 5%;
	display: none;
}

.UserAndLang {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	color: var(--light-black) !important;
}

.NotLogged {
	border-radius: calc(var(--link-height) / 2);
	padding: 10px 28px;
	background-color: var(--dark-blue);
}

.NotLogged::after {
	content: '';
	display: block;
	height: 0;
	overflow: hidden;
	visibility: hidden;
	line-height: 1;
	font-size: inherit;
}

.NotLogged {
	--link-height: calc(1em + 20px);
}

.NotLogged a {
	display: block;
	color: var(--mtc) !important;
}

.BurgerButton {
	display: none;
	cursor: pointer;
}

.Translation {
	display: flex;
	align-items: center;
}

.fa-times {
	display: none;
}

.fa-solid {
	font-size: var(--mbfs);
}

.close-button {
	display: none;
}

@media (max-width: 1440px) {
	.Header {
		padding: 37px 53px;
	}

	.Logo img {
		width: 85px;
		height: 32px;
	}
}

@media (max-width: 1024px) {
	.Header {
		padding: 31px 35px;
	}

	.Logo img {
		width: 71px;
		height: 27px;
	}

	.fa-times {
		display: block;
	}

	.links {
		gap: 25px;
	}

	.NotLogged {
		padding: 5px 15px;
	}
}

@media (max-width: 768px) {
	.Header {
		padding: 25px 25px;
	}

	.Logo img {
		width: 41px;
		height: 16px;
	}

	.Translation {
		display: none;
	}

	.CurrentTranslation {
		width: 54px !important;
	}

	.links {
		gap: 20px;
	}

	.NotLogged {
		padding: 5px 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		text-align: center;
		justify-content: center;
	}


	.links a.active {
		text-decoration: none !important;
		text-underline-offset: 0px !important;
	}

	.links a.active span {
		text-decoration: underline !important;
		text-decoration-color: var(--dark-blue) !important;
		text-decoration-thickness: 2px !important;
		text-underline-offset: 4px !important;
	}

	.links a.active i,
	.links a.active span i {
		text-decoration: none !important;
		text-underline-offset: none;
	}
}

@media (max-width: 680px) {
	.links {
		display: none;
		margin: 0 !important;
	}


	.BurgerButton {
		display: block;
		color: var(--light-black) !important;
	}

	.BurgerButton i {
		display: block;
		color: var(--light-black) !important;
		font-size: 10px;
	}

	.links-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: fixed;
		top: 0;
		right: 0;
		height: 100%;
		width: 50%;
		background-color: var(--mtc);
		font-size: var(--mbfs);
		color: var(--dark-blue);
		flex-direction: column;
		justify-content: center;
		align-items: center;
		transform: translateX(100%);
		opacity: 0;
		visibility: hidden;
		transition: transform 0.5s, opacity 0.5s, visibility 0.5s;
		z-index: 10001;
	}

	.links-container.visible {
		padding-top: 60px;
		transform: translateX(0);
		opacity: 1;
		visibility: visible;
		justify-content: start;
		text-align: start;
		align-items: start;
	}

	.links-container .close-button {
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
		display: block;
	}

	.links-container .links {
		margin-left: 0;
		margin-top: 60px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: center;
		top: 0px;
		width: 100%;
	}
}

@media (max-width: 425px) {
	.Header {
		padding: 20px 20px;
	}

	.links-container {
		width: 100%;
	}

	.NotLogged {
		padding: 4px 10px;
	}
}

@media (max-width: 320px) {
	.Header {
		padding: 15px 15px;
	}

	.Logo img {
		width: 37px;
		height: 14px;
	}
}